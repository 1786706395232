import React from 'react';
import Daftargaleri from './Daftargaleri.admin';
import Addgaleri from './Galeriadd.admin';

const Galeri = ()=>{
    return(
        <div>
            <Addgaleri/>
            <Daftargaleri/>
        </div>
    )
}

export default Galeri;