import React from 'react';
import DaftarAlumni from './DaftarAlumni.admin';
import Daftartransitalumni from './Daftartransitalumni.admin';

const Alumni = ()=>{
    return(
        <div>
            <Daftartransitalumni/>
           <DaftarAlumni/>
        </div>
    )
}

export default Alumni;