import React from 'react';
import Daftarsiswa from './Daftarsiswa.admin';

const Siswa = ()=>{
    return(
        <div >
            <Daftarsiswa/>
        </div>
    )
}

export default Siswa;