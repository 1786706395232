import React from 'react';

const Setting = ()=>{
    return(
        <div>
            <p>ini halaman setting</p>
        </div>
    )
}

export default Setting;