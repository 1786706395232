import React from 'react';
import Daftarfasilitas from './Daftarfasilitas.admin';
import Fasilitasadd from './Fasilitas-add.admin';

const Fasilitas = ()=>{
    return(
        <div>
            <Fasilitasadd/>
            <Daftarfasilitas/>
        </div>
    )
}

export default Fasilitas;